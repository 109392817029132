import React, {useState, useEffect} from "react"
import "./style.css";
// import ScrollAnimation from 'react-animate-on-scroll'

export default function NewShop() {

  // ========== HomePage JSX ============

  return (
    <div id="bakery" className="bakery">

      <div class="background" />
      <div className="backgroundShop"/>

      <div className="content">
        <h1>
          Bear<br/>
          Market<br/>
          Bakery
        </h1>
        <h3>bear markets are for bakers!</h3>
        <p>fren, where you been? did you not get one of my treats for <em>free?!</em></p>
        <br />
        <p>
          the bear market bakery was our pre-launch project. did you miss out? no problem! you can still grab one below:
        </p>
        <br />
        <a class="button" href="https://looksrare.org/collections/0x745735600DCf9562060BEcDAE9A1a0AFfFcd9Cf6?queryID=dd393ed4fb5755190fe5ffcd1b5f78e2" target="_blank" rel="noopener noreferrer">
          <div class="inset green">
            <span>LooksRare →</span>
          </div>
        </a>
        <br />
        <a class="button" href="https://opensea.io/collection/kikobakes" target="_blank" rel="noopener noreferrer">
          <div class="inset green">
            <span>OpenSea →</span>
          </div>
        </a>
        <br/>
        <p>
          why would you want any baked goods? good question <i class="emoji kikoshrug" /> check out what's going on with the <a href="/mystics">mystics</a>. don't miss out!
        </p>
        <br/>
        <p>
          anyone been sniffin the contract?
        </p>
      </div>

      <div class="filler" />

    </div>
  );
}
